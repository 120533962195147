





































































































































































































import { Component, Ref, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { NavigationGuardNext, Route } from "vue-router";
import { cloneDeep } from "lodash";

import FunnelInfo from "@/funnels/components/FunnelInfo.vue";
import FunnelSteps from "@/funnels/components/FunnelSteps.vue";
import FunnelFilters from "@/funnels/components/FunnelFilters.vue";
import FunnelBreakdowns from "@/funnels/components/FunnelBreakdowns.vue";
import FunnelModel, { FunnelStatus } from "@/funnels/models/FunnelModel";
import { GetFunnelRequestModel } from "@/funnels/models/FunnelRequestModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { FilterId, AppSection, MenuItems } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    FunnelInfo,
    FunnelSteps,
    FunnelFilters,
    FunnelBreakdowns,
  },
})
export default class FunnelView extends mixins(UnsavedChangesMixin) {
  @Ref("form") form!: VueForm;

  readonly FunnelStatus = FunnelStatus;
  readonly items = [
    { text: this.$lang("funnel.tabs.info"), icon: "mdi-information-outline" },
    { text: this.$lang("funnel.tabs.filters"), icon: "mdi-filter-cog-outline" },
    { text: this.$lang("funnel.tabs.steps"), icon: "mdi-format-list-numbered" },
    { text: this.$lang("funnel.tabs.breakdowns"), icon: "mdi-cog-outline" },
  ];
  readonly formData = [
    {
      id: 0,
      title: this.$lang("funnel.tabs.info"),
      subtitle: this.$lang("funnel.infoSubtitle"),
    },
    {
      id: 1,
      title: this.$lang("funnel.tabs.filters"),
      subtitle: this.$lang("funnel.filtersSubtitle"),
    },
    {
      id: 2,
      title: this.$lang("funnel.tabs.steps"),
      subtitle: this.$lang("funnel.stepsSubtitle"),
    },
    {
      id: 3,
      title: this.$lang("funnel.tabs.breakdowns"),
      subtitle: this.$lang("funnel.breakdownsSubtitle"),
    },
  ];
  readonly requiredArrayRules = [
    ValidUtil.requiredArray(this.$lang("validation.required")),
  ];
  localFunnel: FunnelModel = new FunnelModel(this.applicationId);
  watchedFunnel = false;
  valid = false;
  selectedListItem = 0;
  prevRouteName = AppSection.FUNNELS;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localFunnel);
  }

  get funnelId(): number {
    return Number.parseInt(this.$route.params.funnelId);
  }

  get funnel(): FunnelModel {
    return this.$store.state.funnelStore.funnel;
  }

  get funnelName(): string {
    return this.funnel.name ?? "";
  }

  get formTitle(): string {
    return (
      this.formData.find((item) => item.id === this.selectedListItem)?.title ??
      ""
    );
  }

  get formSubtitle(): string {
    return (
      this.formData.find((item) => item.id === this.selectedListItem)
        ?.subtitle ?? ""
    );
  }

  @Watch("funnel", { deep: true })
  private watchFunnel(funnel: FunnelModel) {
    this.localFunnel = FunnelModel.of(cloneDeep(funnel), [
      FilterId.ATTRIBUTION_DATE,
    ]);

    if (!this.watchedFunnel) {
      this.watchedFunnel = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  async created() {
    await this.$store.dispatch(
      "getFunnel",
      new GetFunnelRequestModel(this.funnelId, this.applicationId)
    );

    this.$store.dispatch("loadFunnelDictionaries", {
      app: this.applicationId,
      funnel: this.funnel,
    });

    document.title = this.$lang(
      "documentTitle",
      this.$lang("funnel.viewTitle", this.funnel.name ?? "")
    );
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    next((vm: any) => {
      vm.prevRouteName = from.name;
    });
  }

  async save() {
    if (!this.form.validate()) {
      return;
    }

    const payload: Record<string, any> = {};

    for (const [key, value] of Object.entries(this.localFunnel)) {
      if (["breakdowns", "filter"].includes(key)) {
        if (
          JSON.stringify(this.funnel[key as keyof FunnelModel]) !==
          JSON.stringify(value)
        ) {
          payload[key] = value;
        }
      } else if (key === "steps") {
        const funnelStepsFiltered = this.funnel.steps.map(
          (item: Record<string, any>) => ({ ...item, key: undefined })
        );
        const localFunnelStepsFiltered = this.localFunnel.steps.map(
          (item: Record<string, any>) => ({ ...item, key: undefined })
        );

        if (
          JSON.stringify(funnelStepsFiltered) !==
          JSON.stringify(localFunnelStepsFiltered)
        ) {
          payload[key] = value;
        }
      } else if (this.funnel[key as keyof FunnelModel] !== value) {
        payload[key] = value;
      }
    }

    await this.$store.dispatch("updateFunnel", {
      payload,
      id: this.funnelId,
      applicationId: this.applicationId,
      funnelName: this.funnel.name,
    });

    this.isSavedForm = true;
    this.$router.push({
      name: Object.keys(payload).some((item) =>
        ["breakdowns", "filter", "steps", "eventsFrom", "eventsTo"].includes(
          item
        )
      )
        ? MenuItems.FUNNEL
        : this.prevRouteName,
      params: { id: this.applicationId },
    });
  }

  cancel() {
    this.$router.push({
      name: this.prevRouteName,
      params: { id: this.applicationId },
    });
  }
}
