var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-sheet',{ref:"funnelChartHeader",staticClass:"pa-2",style:(("border-bottom: 1px solid " + (_vm.dark ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)')))},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{class:[
            'text-decoration-none d-flex align-center route-link',
            { dark: _vm.dark } ],attrs:{"to":{
            name: 'funnel',
            params: { id: _vm.applicationId },
          }}},[_c('v-icon',{attrs:{"color":_vm.dark ? 'grey lighten-3' : 'grey darken-3',"size":"20"}},[_vm._v(" mdi-chevron-left ")]),_c('span',{class:[
              'ml-1 text-body-2 grey--text',
              _vm.dark ? 'text--lighten-3' : 'text--darken-3' ]},[_vm._v(" "+_vm._s(_vm.$lang("funnel.goToFunnelList"))+" ")])],1)],1),_c('v-spacer'),(_vm.localFunnel.status === _vm.FunnelStatus.ACTIVE)?_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{class:[
            'text-decoration-none d-flex align-center route-link',
            { dark: _vm.dark } ],attrs:{"to":{
            name: 'funnel_chart_view',
            params: {
              id: _vm.applicationId,
              funnelId: _vm.funnel.id,
            },
          }}},[_c('span',{class:[
              'ml-1 text-body-2 grey--text',
              _vm.dark ? 'text--lighten-3' : 'text--darken-3' ]},[_vm._v(" "+_vm._s(_vm.$lang("funnel.goToFunnelView"))+" ")]),_c('v-icon',{attrs:{"color":_vm.dark ? 'grey lighten-3' : 'grey darken-3',"size":"20"}},[_vm._v(" mdi-chevron-right ")])],1)],1):_vm._e()],1),_c('v-row',{staticClass:"align-center mt-0"},[_c('v-col',{staticClass:"text-h5 font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$lang("funnel.detailsTitle", _vm.funnelName))+" ")]),_c('v-spacer'),(_vm.localFunnel.editable)?_c('v-col',{attrs:{"cols":"auto"}},[(_vm.hasUnsavedChanges)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-2",attrs:{"fab":"","small":""},on:{"click":_vm.cancel}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,1785883565)},[_vm._v(" "+_vm._s(_vm.$lang("btn.cancel"))+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 elevation-2",attrs:{"color":"primary","disabled":!_vm.hasUnsavedChanges || !_vm.valid,"fab":"","small":""},on:{"click":_vm.save}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,false,3005811362)},[_vm._v(" "+_vm._s(_vm.$lang("btn.save"))+" ")])],1):_vm._e()],1)],1),_c('div',{staticClass:"d-flex pa-2"},[_c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-list',{attrs:{"rounded":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.selectedListItem),callback:function ($$v) {_vm.selectedListItem=$$v},expression:"selectedListItem"}},_vm._l((_vm.items),function(ref,index){
            var icon = ref.icon;
            var text = ref.text;
return _c('v-list-item',{key:index},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)],1)}),1)],1)],1)],1),_c('div',{staticClass:"flex-grow-1 pl-2"},[_c('v-responsive',{staticClass:"overflow-y-auto",staticStyle:{"flex":"auto"},attrs:{"height":"calc(100vh - 169px)"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-h6 font-weight-medium pa-3"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")]),_c('v-card-subtitle',{staticClass:"px-3 py-0"},[_vm._v(" "+_vm._s(_vm.formSubtitle)+" ")]),_c('v-card-text',{staticClass:"pa-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.selectedListItem === 0)?_c('funnel-info',{attrs:{"readonly":!_vm.localFunnel.editable,"is-edit":true},model:{value:(_vm.localFunnel),callback:function ($$v) {_vm.localFunnel=$$v},expression:"localFunnel"}}):_vm._e(),(_vm.selectedListItem === 1)?_c('funnel-filters',{attrs:{"application-id":_vm.applicationId,"is-edit":true,"rules":_vm.requiredArrayRules,"readonly":!_vm.localFunnel.editable},model:{value:(_vm.localFunnel),callback:function ($$v) {_vm.localFunnel=$$v},expression:"localFunnel"}}):_vm._e(),(_vm.selectedListItem === 2)?_c('funnel-steps',{attrs:{"type":_vm.localFunnel.type,"application-id":_vm.applicationId,"is-edit":true,"rules":_vm.requiredArrayRules,"readonly":!_vm.localFunnel.editable},model:{value:(_vm.localFunnel),callback:function ($$v) {_vm.localFunnel=$$v},expression:"localFunnel"}}):_vm._e(),(_vm.selectedListItem === 3)?_c('funnel-breakdowns',{attrs:{"is-edit":true,"readonly":!_vm.localFunnel.editable},model:{value:(_vm.localFunnel),callback:function ($$v) {_vm.localFunnel=$$v},expression:"localFunnel"}}):_vm._e()],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }