


















import { Component, Prop, Vue } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import FunnelModel from "@/funnels/models/FunnelModel";
import {
  FilterId,
  FUNNEL_BREAKDOWN_FILTER_ID,
  ValidationRule,
} from "@/shared/models";

@Component
export default class FunnelBreakdowns extends Vue {
  @Prop() value!: FunnelModel;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) isEdit!: boolean;
  @Prop() rules?: Array<ValidationRule>;

  readonly breakdownItems = FUNNEL_BREAKDOWN_FILTER_ID.map((it) => ({
    name: this.$lang(`shared.filters.${it.toString().toLowerCase()}`),
    value: it,
  }));

  get localValue(): FunnelModel {
    return this.value;
  }

  get localValueBreakdowns(): Array<FilterId> {
    return this.localValue.breakdowns;
  }

  set localValueBreakdowns(value: Array<FilterId>) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.breakdowns = value;
    this.$emit("input", clonedLocalValue);
  }
}
